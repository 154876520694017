jQuery(function($) {
  var $smooth  = $('a[href^="#"]');
  // #で始まるアンカーをクリックした場合に処理
  $smooth.on('click', function() {
    // スクロールの速度
    var speed = 800;// ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href === '#top' || href === '#' || href === '' ? 'html' : href);
    // var nav = $("#nav").height();
    var header =  $('#header .inner').outerHeight();
    // 移動先を数値で取得
    var position = target.offset().top - header;
    // スムーススクロール
     if( !$('body html').is(':animated') ) {
        $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
    }
    return false;
  });

  /*----- slider -----*/
  if($("#index").length){
    var swiper = new Swiper('.swiper-container', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    });
  }

  /*----- accordion -----*/
  $('.acco_btn').on('click',function() {
    if(!$('.acco_cont').is(':animated')) {
      $(this).toggleClass('active').next('.acco_cont').slideToggle();
    }
  });

  /*----- header_fixed -----*/
  $(window).on('load scroll',function(){
    if($('#header').length) {
      var
      $elmTop  = ($("#results").offset().top)-(-300)
      $formTop = ($("#footer").offset().top)-(200)
      $thisTop = $(this).scrollTop()
      if( $elmTop > $thisTop || $formTop < $thisTop) {
        $("#header").fadeOut()
      } else {
        $("#header").fadeIn()
      }
    }
  });

  /*----- 住所自動入力 -----*/
  $("#postcode1").jpostal({
    postcode : [
      "#postcode1",
      "#postcode2"
    ],
    address : {
      "#area"  : "%3",
      "#address"  : "%4%5"
    }
  });

});
